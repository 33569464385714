.table-tiny th,
.table-tiny td {
  padding-left: 8px;
  padding-right: 8px;
  border-color: lightgray;
  vertical-align: top;
}

.table-tiny th {
  height: 50px;
}

.inner-table td {
  padding: 4px;
}

.accordion-item {
  background: lightgray;
}