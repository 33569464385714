.custom-table th,
.custom-table td {
  padding-left: 4px;
  padding-right: 4px;
  /* text-align: center; */
}

.custom-table th {
  
  height: 50px;
}